<template>
  <div>
    <el-card class="box-card back-card-class">
      <div class="detail-header">
        <div class="divOne">
          <p>Name: {{detail.name}}</p>
          <p>Organization: {{detail.organization}}</p>
          <p>County: {{detail.country}}</p>
        </div>
        <div class="divOne">
          <p>Email: {{detail.email}}</p>
          <p>Department: {{detail.department}}</p>
          <p>Time: {{detail.dateTime}}</p>
        </div>
      </div>
      <hr/>
      <div class="detail-center">
        <div class="detail-img">
          <p>Upload</p>
          <div>
            <img :src="`https://www.cropopen.com:8009/images/${detail.sourcePath}`" alt="loading..."/>
          </div>
        </div>
        <div class="detail-img">
          <p>Result</p>
          <div>
            <img :src="`https://www.cropopen.com:8009/images/${detail.outPath}`" alt="loading..."/>
          </div>
        </div>
        <div class="detail-data">
          <div class="detail-org-item">
            <p>Organelle</p>
            <p>Chloroplast</p>
            <p>Mitochondria</p>
            <p>Nucleus</p>
            <p>Vacuole</p>
          </div>
          <div class="detail-org-item">
            <p>Size/Area</p>
            <p v-text="detail.chloroplast"></p>
            <p v-text="detail.mitochondria"></p>
            <p v-text="detail.nucleus"></p>
            <p v-text="detail.vacuole"></p>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      detail:{
        chloroplast:'',
        country:'',
        dateTime:'',
        department:'',
        mitochondria:'',
        nucleus:"",
        organization:"",
        outPath:'',
        sourcePath:"",
        state:"",
        vacuole:"",
        name:"",
        email:""
      }
    }
  },
  mounted() {
    this.detail=JSON.parse(window.sessionStorage.getItem('value'))
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
.back-card-class{
  margin-top: 15px;
  height:85vh;
  min-height: 800px;
  overflow-y: scroll;
  .detail-header{
    height:130px;
    display: flex;
    align-items: center;
    .divOne{
      line-height:30px;
      width: 550px;
    }
  }
  .detail-center{
    height: 420px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .detail-img{
      width: 420px;
      p{
        height: 60px;
        line-height: 60px;
      }
      div{
        width:420px;
        height:350px;
        img{
          width:420px;
          height:350px;
        }
      }
    }
    .detail-data{
      width: 470px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail-org-item{
        width: 230px;
        margin-top:60px;
        p{
          padding: 0 0 0 60px;
          margin: 0;
          line-height: 70px;
          height: 70px;
          border-bottom: #bdceb7 1px solid;
        }
        :nth-child(1){
          background-color: #f3f6f2;
          border-bottom: 0;
        }
      }
    }
  }
}

</style>